import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { ShareableContainer, DocumentWrapper } from 'components';
import throttle from 'lodash/throttle';
import Sample from '../assets/SamplePDF.pdf';
import Measure from 'react-measure';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { GiConsoleController } from 'react-icons/gi';
import { BsArrowUpDown } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import './PDF.css';
import qs from 'qs';
import Store from 'store';
import GlobalEvent, { useEvent } from 'js-events-listener/react';

export default function PDF(props) {
  const { id, instance } = useParams();
  const isForce = window.location.href.includes('force=1');
  const nocowatch = window.location.href.includes('no_cowatch=1');
  const nobg = window.location.href.includes('no_bg=1');

  if (nobg) {
    document.body.style.backgroundColor = 'transparent';
  }

  const idInstance = id + (instance || '');
  // const [pdfUrl, setPdfUrl] = useState(!!id ? `${window.location.protocol}//${window.location.host}/pdf-assets/${id}.pdf` : Sample);
  const pdfUrl = `https://theatro360-uploads.s3.eu-west-2.amazonaws.com/${id}.pdf`;
  // console.log('pdfUrl', pdfUrl);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [showControl, setShowControl] = useState(false);

  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [pageWidth, setPageWidth] = useState(0);
  const [pageHeight, setPageHeight] = useState(0);
  const [scale, setScale] = useState(1);

  const pageNumberRef = useRef(1);
  const scaleRef = useRef(1);
  const wrapRef = useRef(null);
  const wrapperHeightRef = useRef(0);
  const wrapperWidthRef = useRef(0);
  const scrollLeftRef = useRef(0);
  const scrollTopRef = useRef(0);

  const getCurrentData = () => {
    return {
      scale: scaleRef.current,
      xPercent: wrapperWidthRef.current === 0 ? 0 : scrollLeftRef.current / wrapperWidthRef.current,
      yPercent: wrapperHeightRef.current === 0 ? 0 : scrollTopRef.current / wrapperHeightRef.current,
      pageNumber: pageNumberRef.current,
    };
  }

  const emitControl = () => {
    Store.Socket.emitControl('pdf', idInstance, getCurrentData());
  };

  const followControl = ({ data }) => {
    // console.log('followControl', data);
    const { xPercent, yPercent } = data;
    if (data.scale !== scaleRef.current) setScale(data.scale);
    if (data.pageNumber !== pageNumberRef.current) setPageNumber(data.pageNumber);
    setScroll(xPercent, yPercent);
  }

  useEffect(() => {
    if (nocowatch) return;
    // console.log('init socket')
    Store.Socket.init();
    setTimeout(() => {
      Store.Socket.joinRoom('pdf', idInstance, getCurrentData(), isForce);
    });
    return () => {
      Store.Socket.leaveRoom('pdf', idInstance);
    };
  }, [idInstance, nocowatch]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    wrapperHeightRef.current = wrapperHeight;
    wrapperWidthRef.current = wrapperWidth;
    scaleRef.current = scale;
    pageNumberRef.current = pageNumber;
    emitControl();
  }, [wrapperHeight, wrapperWidth, scale, pageNumber]);

  useEvent('CONTROL_pdf_'+idInstance, (data : any) => {
    const shouldFollow = data.admin !== data.socketId;
    if (shouldFollow) {
      setShowControl(false);
      followControl(data);
    }
    else { setShowControl(true); }
  }, [idInstance]);

  useEvent('NEW_ADMIN_pdf_'+idInstance, (data : any) => {
    setShowControl(data.isMe);
  }, [idInstance]);

  const setScroll = useCallback((xPercent, yPercent) => {
    if (!wrapRef.current) return;
    const valueLeft = xPercent * wrapperWidthRef.current;
    const valueTop = yPercent * wrapperHeightRef.current;
    wrapRef.current.scrollTo(valueLeft, valueTop);
  }, []);

  const method = useCallback(() => {
    return ({
      setNumPages: setNumPages,
      setPageNumber: setPageNumber,
      setScale: setScale,
      setScroll: setScroll,
    });
  }, []);

  const fitHorizontal = useMemo(() => {
    const wRatio = pageWidth / wrapperWidth;
    const hRatio = pageHeight / wrapperHeight;
    if (wRatio < hRatio) {
      return false;
    }
    return true;
  }, [pageHeight, pageWidth, wrapperHeight, wrapperWidth]);

  const setWrapperDimensions = useCallback(throttle((w, h) => {
    setWrapperWidth(w);
    setWrapperHeight(h);
  }, 500), []);

  const renderControl = () => {
    return (
      <>
        <div className="co-watch-pdf-control">
          <div className="info-row">
            {Boolean(scale !== 1) && (
              <span onClick={() => setScale(1)}>Reset zoom</span>
            )}
          </div>
          <div className="buttons-row">
            <FaCaretLeft
              color={pageNumber === 1 ? 'rgba(0,0,0,0.5)' : 'black'}
              size={30} onClick={() => setPageNumber(v => Math.max(1, v - 1))}
            />
            <BiZoomOut size={30} onClick={() => setScale(v => v - 0.2)} />
            <BiZoomIn size={30} onClick={() => setScale(v => v + 0.2)} />
            <FaCaretRight
              size={30} onClick={() => setPageNumber(Math.min(pageNumber + 1, numPages))}
              color={pageNumber === numPages ? 'rgba(0,0,0,0.5)' : 'black'}
            />
          </div>
          <div className="info-row">
            <p>Page {pageNumber} of {numPages}</p>
          </div>
        </div>
        <div className={`co-watch-in-control ${showControl ? 'show' : ''}`}>
          <BsArrowUpDown size={12} />
        </div>
      </>
    );
  };

  return (
    <ShareableContainer id="test" method={method}>
      <Measure
        bounds
        onResize={(contentRect) => setWrapperDimensions(contentRect.bounds.width, contentRect.bounds.height)}
      >
        {({ measureRef }) => (
          <DocumentWrapper
            ref={ref => {
              if (typeof measureRef === 'function') measureRef(ref);
              else measureRef.current = measureRef;
              wrapRef.current = ref;
            }}
            onScroll={(e) => {
              const { scrollLeft, scrollTop } = e.nativeEvent.target;
              scrollLeftRef.current = scrollLeft;
              scrollTopRef.current = scrollTop;
              emitControl();
            }}
            className={`co-watch-pdf-wrapper ${nobg ? 'nobg' : ''}` }
          >
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className='co-watch-pdf-container'
            >
              <Page
                key="page"
                className='co-watch-pdf-page'
                onLoadSuccess={(page) => {
                  setPageWidth(page.width);
                  setPageHeight(page.height);
                }}
                width={fitHorizontal ? wrapperWidth : null}
                height={!fitHorizontal ? wrapperHeight : null}
                scale={scale}
                pageNumber={pageNumber}
              />
            </Document>
            {renderControl()}
          </DocumentWrapper>
        )}
      </Measure>
    </ShareableContainer>
  );
}