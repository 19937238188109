import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import { Document, Page } from 'react-pdf';
import { ShareableContainer, DocumentWrapper } from 'components';
import throttle from 'lodash/throttle';
import Sample from '../assets/SamplePDF.pdf';
import Measure from 'react-measure';
import { FaCaretLeft, FaCaretRight } from 'react-icons/fa';
import { BiZoomIn, BiZoomOut } from 'react-icons/bi';
import { GiConsoleController } from 'react-icons/gi';
import { BsArrowUpDown } from 'react-icons/bs';
import { useParams } from 'react-router-dom';
import './PDF.css';
import './Vimeo.css';
import qs from 'qs';
import Store from 'store';
import GlobalEvent, { useEvent } from 'js-events-listener/react';
import Player from '@vimeo/player';

// let _thTime = 0;
// const throttle = (func, milisec) => {
//   const delta = new Date().getTime() - _thTime;
//   if (delta > milisec) {
//     func();
//     _thTime = new Date().getTime();
//   }
// }

const Vimeo = () => {
  const nocowatch = window.location.href.includes('no_cowatch=1');
  const isForce = window.location.href.includes('force=1');
  const nobg = window.location.href.includes('no_bg=1');

  if (nobg) {
    document.body.style.backgroundColor = 'transparent';
  }

  const playerRef = useRef(null);
  const currentTimeRef = useRef(null);
  const isPlayingRef = useRef(false);
  const isPausedRef = useRef(false);
  const [showControl, setShowControl] = useState(false);
  const showControlRef = useRef(false);

  useEffect(() => {
    showControlRef.current = showControl;
  }, [showControl]);

  const { id, instance } = useParams();

  const method = useCallback(() => {
    return ({
      setCurrentTime: (seconds) => {
        playerRef.current?.setCurrentTime(seconds);
      },
      play: () => playerRef.current?.play(),
      pause: () => playerRef.current?.pause(),
    });
  }, []);

  useEffect(() => {
    // 76979871
    playerRef.current = new Player('handstick', {
      id,
    });
    playerRef.current?.ready().then(function() {
      // the player is ready
      setTimeout(() => {
        Store.Socket.getCurrentData('vimeo', idInstance);
      }, 500);
      setTimeout(() => {
        Store.Socket.getCurrentData('vimeo', idInstance);
      }, 1500);
    });
    playerRef.current?.on('play', function() {
      // console.log('play');
      isPlayingRef.current = true;
      isPausedRef.current = false;
      showControlRef.current && emitControl();
    });
    playerRef.current?.on('pause', function() {
      // console.log('pause');
      isPlayingRef.current = false;
      isPausedRef.current = true;
      showControlRef.current && emitControl();
    });
    playerRef.current?.on('timeupdate', function(currentTime) {
      // console.log(currentTime);
      currentTimeRef.current = currentTime;
      throttle(() => {
        showControlRef.current && emitControl();
      }, 2000);
      
    });
    return () => {
      playerRef.current?.off('play');
      playerRef.current?.off('pause');
      playerRef.current?.off('timeupdate');
    }
  }, [id]);

  const idInstance = id + (instance || '');

  const getCurrentData = () => {
    return {
      currentTime: currentTimeRef.current,
      isPlaying: isPlayingRef.current,
      isPaused: isPausedRef.current,
    };
  };

  const emitControl = () => {
    Store.Socket.emitControl('vimeo', idInstance, getCurrentData());
  };

  const followControl = ({ data }) => {
    console.log('followControl', data);
    const { isPaused, isPlaying, currentTime } = data;
    const seconds = !!currentTime ? currentTime.seconds : 0;
    if (!!isPlaying) {
      method().play();
    } else if (!!isPaused && !getCurrentData().isPaused) {
      method().pause();
    }
    if (!getCurrentData().currentTime) {
      method().setCurrentTime(seconds + 2);
    } else if (
      (!!getCurrentData().currentTime && seconds !== getCurrentData().currentTime.seconds)
      && (Math.abs(seconds - getCurrentData().currentTime.seconds) >= 4)
    ) {
      method().setCurrentTime(seconds);
    }
  };

  useEffect(() => {
    if (nocowatch) return;
    Store.Socket.init();
    setTimeout(() => {
      Store.Socket.joinRoom('vimeo', idInstance, getCurrentData(), isForce);
    });
    return () => {
      Store.Socket.leaveRoom('vimeo', idInstance);
    };
  }, [idInstance, nocowatch]);

  useEvent('CONTROL_vimeo_'+idInstance, (data : any) => {
    // console.log('CONTROL_vimeo_'+idInstance, data);
    const shouldFollow = data.admin !== data.socketId;
    if (shouldFollow) {
      setShowControl(false);
      followControl(data);
    }
    else { setShowControl(true); }
  }, [idInstance]);

  useEvent('NEW_ADMIN_vimeo_'+idInstance, (data : any) => {
    setShowControl(data.isMe);
  }, [idInstance]);

  const renderControl = () => {
    return (
      <>
        <div className={`co-watch-in-control bottom-right ${showControl ? 'show' : ''}`}>
          <BsArrowUpDown size={12} />
        </div>
      </>
    );
  };

  return (
    <ShareableContainer id="testvimeo" method={method}>
      <iframe
        id={'handstick'}
        src={`https://player.vimeo.com/video/${id}`}
        allowFullScreen allow="autoplay; encrypted-media"
        style={{ width: '100%', height: '100%', border: 'none', backgroundColor: nobg ? 'transparent' : 'black' }}
      />
      {renderControl()}
    </ShareableContainer>
  );
};

export default Vimeo;