

import React, { useState, useEffect } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';

import { PDF, Vimeo } from './screens';

const Router = () => {

  const [ready, setReady] = useState(true);

  if (!ready) return null;
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/pdf/:id/:instance" component={PDF} exact />
        <Route path="/pdf/:id" component={PDF} exact />
        <Route path="/vimeo/:id/:instance" component={Vimeo} exact />
        <Route path="/vimeo/:id" component={Vimeo} exact />
      </Switch>
    </BrowserRouter>
  );
}

export default Router;
