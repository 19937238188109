import io from 'socket.io-client';
import GlobalEvent from 'js-events-listener';

class SocketStore {

  socket;

  init() {
    if (!this.socket) {
      let host = `${window.location.host}`;
      if (host.includes('localhost')) {
        host = host.replace(':3000', ':2000');
      }
      this.socket = io(host, {
        // transports: ['websocket']
      });
      this.setupListener();
    }
  }

  setupListener() {
    this.socket.on('roomInitialData', (data) => {
      GlobalEvent.emit('CONTROL_'+data.name, {
        ...data,
        socketId: this.socket.id,
      });
    });
      
    this.socket.on('control', (data) => {
      // console.log('on control', data);
      GlobalEvent.emit('CONTROL_'+data.name, {
        ...data,
        socketId: this.socket.id,
      });
    });

    this.socket.on('newAdmin', (data) => {
      const computeData = {
        ...data,
        isMe: this.socket.id === data.id,
      };
      // console.log('newAdmin', computeData);
      GlobalEvent.emit('NEW_ADMIN_'+data.roomName, computeData);
    });

    this.socket.on("connect_error", err => {
      console.log(err instanceof Error); 
      console.log(err.message); 
      console.log(err.data);
    });
  }

  joinRoom(type: 'pdf' | any, id, data, isForce = false) {
    this.socket.emit('join', { type, id, data, isForce });
  }

  leaveRoom(type, id) {
    this.socket.emit('leave', { type, id });
  }

  emitControl(type, id, data) {
    if (!this.socket) return;
    this.socket.emit('control', { type, id, data });
  }

  getCurrentData(type, id) {
    if (!this.socket) return;
    this.socket.emit('getData', { type, id });
  }
}

export default new SocketStore();