import React, { useEffect } from 'react';

declare var window;

const ShareableContainer = (props) => {

  useEffect(( ) => {
    if (props.id) {
      window.shareEvent = !window.shareEvent ? {} : window.shareEvent;
      window.shareEvent[props.id] = props.method();
    }
  }, [props.id, props.rerenderKey]);

  return (
    props.children
  );
};

export default ShareableContainer;